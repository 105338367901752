<template>
  <div v-if="formData">
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-usuario" v-if="formData.usuario">
        Informaçẽos do Usuário
        <i :class="$utilidade.getIcone('usuario') + ' fa-2x'"></i>
      </v-tab>

      <v-tab href="#tab-empresa" v-if="formData.empresa">
        Informaçẽos da Empresa
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent && bind.boolCarregado"
    >
      <cadastro-usuario :formData="formData.usuario" v-if="strTab == 'tab-usuario' && formData.usuario"  :key="'tab-usuario-' + $root.$session.versao" />
      <cadastro-empresa :formData="formData.empresa" v-if="strTab == 'tab-empresa' && formData.empresa"  :key="'tab-empresa-' + $root.$session.versao" />
    </ed-form>
  </div>
</template>

<script>
import CadastroEmpresa from "./partials/empresa";
import CadastroUsuario from "./partials/usuario";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {

  },
  components: {
    EdForm,
    CadastroEmpresa,
    CadastroUsuario
  },
  provide() {
    return {
      bind: this.bind,
    };
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      strTab: "tab-usuario",
      bind: {
        boolCarregado:false
      },
      formData:{
        usuario:null,
        empresa:null,
      },
    };
  },
  watch: {
  },
  methods: {


    initialize() {
      this.$root.$dialog.loading(true);

      Promise.all([
        this.$root.$request.get("System/Cliente", {
          boolMeuCadastro: true,
        }),
      ]).then(([objDados]) => {
        this.$root.$dialog.loading(false);

        if (objDados.status == 200) {
          
          if(objDados.result.empresa){
            objDados.result.empresa.arquivo_logo = null;
          }

          this.formData = Object.assign(objDados.result,{})
          this.bind.boolCarregado = true
        }
      });
    },

    salvar() {

      this.$root.$dialog.loading(true);

      this.$root.$request
        .put("System/Cliente",this.formData)
        .then((objResponse) => {

          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            //this.initialize()
            this.$root.$message.success("Inforações atualizadas com sucesso");
          }
        });
    },
  },
};
</script>
