<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados de acesso</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7"
          v-model="formData.strNome"
          name="strNome"
          label="Nome"
          readonly
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
          v-model="formData.strCpf"
          name="strCpf"
          label="CPF"
          readonly
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strLogin"
          name="strLogin"
          label="Login"
          readonly
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strEmail"
          name="strEmail"
          label="Email"
          rules="required|email"
        />
      </div>

      <span class="form-title">Alterar Senha</span>
      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strSenhaAtual"
          name="usuario.strSenhaAtual"
          label="Senha Atual"
          rules="required"
          type="password"
        />
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strSenhaNovaSenha"
          name="usuario.strSenhaNovaSenha"
          label="Nova Senha"
          type="password"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.strSenhaConfirmarNovaSenha"
          name="usuario.strSenhaConfirmarNovaSenha"
          label="Confirmar Nova Senha"
          type="password"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdAlert,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdAlert,
    EdInputSelect,
    EdInputTipo,
    EdInputSwitch,
    EdInputAutoComplete,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {
      bind: {},
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {},
      deep: true,
    },
  },
  computed: {},
  methods: {
    setCnae(item) {
      if (item?.strCodigo) {
        this.formData.intGrauRisco = item.intGrauRisco;
      }
    },
  },
};
</script>
