<template>
  <div>
      <ed-usuario :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdUsuario from "@/components/cadastro/meuCadastro/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdUsuario },
  mounted() {
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>